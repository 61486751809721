import Vue from 'vue'
import App from './App.vue'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import router from './router'
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrVU96iI5MPvdIOIFa4D1ALlt2g8imhL8",
  authDomain: "gajendra-trading-1.firebaseapp.com",
  projectId: "gajendra-trading-1",
  storageBucket: "gajendra-trading-1.appspot.com",
  messagingSenderId: "265217041854",
  appId: "1:265217041854:web:5f957bda2019db445085ef",
  measurementId: "G-0SDE27NY2M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

Vue.prototype.$app = app;
Vue.config.productionTip = false
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.use(VueMaterial)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
