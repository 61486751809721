<template>
  <div>
    <div class="mainMenu">
      <router-link to="/NewIn">
        <md-button class="menu">Create a new In Order</md-button>
      </router-link>
      <router-link to="/NewOut">
        <md-button class="menu">Create a new Out Order</md-button>
      </router-link>
      <br>
      <br>
      <router-link to="/ViewLiveInventory">
        <md-button class="menu">View Live Inventory</md-button>
      </router-link>
      <md-button class="menu" v-on:click="logout">Logout</md-button>
    </div>
  </div>
</template>

<script>
import {signOut, getAuth, onAuthStateChanged} from "firebase/auth";
import router from "@/router";
export default {
  name: "dashboard",
  methods:{
    logout(){
      const auth = getAuth();
      signOut(auth);
      router.replace("/");
    }
  },
  beforeMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (!user)
        await router.replace('/');

    });
  }
}
</script>

<style scoped>
.menu {
  background: #E10032;
  color: white !important;
  border-radius: 12px;
  min-width: 300px;
  min-height: 70px;
}

.mainMenu {
  margin-top: 10%;
  margin-left: 9vw;
  margin-right: 10vw;
}
</style>