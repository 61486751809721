<template>
  <div class="home">
    <md-dialog :md-active.sync="newItemCheck">
      <md-dialog-title>Create Item - '{{this.newItemNameTemp}}'</md-dialog-title>
      <md-dialog-actions>
        <md-button class="md-primary" @click="newItem">Yes</md-button>
        <md-button class="md-primary" @click="newItemCheck = false;  remove(newItemIndex);">No (Delete)</md-button>
      </md-dialog-actions>
    </md-dialog>
    <div class="data_picker">
      <div class="toolbar">
        <md-toolbar class="md-accent toolbar-content">
          <h3 class="md-title">Gajendra Trading Company</h3>
          <h4 class="md-title">In Invoice</h4>
        </md-toolbar>
      </div>
      <div class="quotation">
        <md-card class="quotation-details  md-small-size-100 md-elevation-15 ">
          <md-card-header>
            <div class="md-title" style="text-align: center">Invoice for {{ clientDetails.Name }}</div>
          </md-card-header>
          <hr style="border-style: dashed"/>
          <md-card-header>
            <div class="md-title" style="text-align: center">Receiver Details</div>
          </md-card-header>
          <md-card-content>
            <md-field>
              <label>Name</label>
              <md-input v-model="clientDetails.Name"></md-input>
              <span class="md-helper-text"></span>
            </md-field>
            <md-field>
              <label>Address</label>
              <md-input v-model="clientDetails.Address"></md-input>
              <span class="md-helper-text">Address of Client</span>
            </md-field>
            <md-field>
              <label>GSTIN</label>
              <md-input v-model="clientDetails.GSTIN"></md-input>
            </md-field>
            <md-field>
              <label>State</label>
              <md-input v-model="clientDetails.State"></md-input>
            </md-field>
            <md-field>
              <label>State Code</label>
              <md-input v-model="clientDetails.StateCode"></md-input>
            </md-field>
          </md-card-content>
          <md-card-header>
            <hr style="border-style: dashed"/>
            <div class="md-title" style="text-align: center">Extra Details</div>
          </md-card-header>
          <md-card-content>
            <div class="md-layout-item">
              <md-field>
                <md-select v-model="TypeOfInvoice" placeholder="Type of Invoice">
                  <md-option value="Original for Receiver">Original for Receiver</md-option>
                  <md-option value="Duplicate for Supplier / Transporter">Duplicate for Supplier / Transporter
                  </md-option>
                  <md-option value="Triplicate for Supplier">Triplicate for Supplier</md-option>
                </md-select>
              </md-field>
            </div>
            <md-field>
              <label>Reverse Charge</label>
              <md-input v-model="reverseCharge"></md-input>
            </md-field>
            <md-field>
              <label>Transportation Mode</label>
              <md-input v-model="transportMode"></md-input>
              <span class="md-helper-text">Type of Vehicle</span>
            </md-field>
            <md-field>
              <label>Vehicle Number</label>
              <md-input v-model="vehicleNumber"></md-input>
            </md-field>
            <md-datepicker v-model="dateOfSupplySelected">
              <label>Date of Supply</label>
            </md-datepicker>
            <md-field>
              <label>Place of Supply</label>
              <md-input v-model="placeOfSupply"></md-input>
            </md-field>
          </md-card-content>
          <hr style="border-style: dashed"/>
          <!-- Shipped to SECTION -->
          <md-card-header>
            <div class="md-title" style="text-align: center">Shipped To Details</div>
          </md-card-header>
          <md-card-content>
            <md-field>
              <label>Name</label>
              <md-input v-model="shippedDetails.Name"></md-input>
              <span class="md-helper-text"></span>
            </md-field>
            <md-field>
              <label>Contact</label>
              <md-input v-model="shippedDetails.Address"></md-input>
              <span class="md-helper-text">Address of Shipped To</span>
            </md-field>
            <md-field>
              <label>GSTIN</label>
              <md-input v-model="shippedDetails.GSTIN"></md-input>
            </md-field>
            <md-field>
              <label>State</label>
              <md-input v-model="shippedDetails.State"></md-input>
            </md-field>
            <md-field>
              <label>State Code</label>
              <md-input v-model="shippedDetails.StateCode"></md-input>
            </md-field>
          </md-card-content>
          <hr style="border-style: dashed"/>
          <md-card-header>
            <div class="md-title" style="text-align: center">Product Details</div>
          </md-card-header>
          <md-card-content>
            <div class="items" v-for="(item, index) in items" :key="index">
              <div class="cut md-accent" @click="remove(index)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-x-square" viewBox="0 0 16 16">
                  <path
                      d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                  <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </div>
              <div class="itemName">
                <md-field>
                  <label>Item Name</label>
                  <md-input v-model="item.itemName" v-on:input="ItemCountUpdater(index)" @blur="CheckItem(index)"/>
                </md-field>
                <md-field>
                  <label>Number of Boxes</label>
                  <md-input v-model="item.itemNoBox" type="number" @input="item_total_updater(index)"/>
                  <span class="md-helper-text" v-if="currentCount[index].count !== -1">Current Count is <b>{{currentCount[index].count}}</b></span>
                </md-field>
                <md-field>
                  <label>Weight of Each Box Kgs</label>
                  <md-input v-model="item.itemWeightBox" type="number" @input="item_total_updater(index)"/>
                </md-field>
                <md-field>
                  <label>Rate</label>
                  <md-input v-model="item.itemPrice" type="number" @input="item_total_updater(index)"/>
                </md-field>
                <div class="cut" :md-ripple="false" @click="add()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-plus-square" viewBox="0 0 16 16">
                    <path
                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg>
                </div>
                <span class="md-title" style="display: flex; justify-content: center">Total Weight for {{
                    item.itemName
                  }} is {{ item.itemTotalWeight }} Kgs</span>
                <span class="md-title" style="display: flex; justify-content: center">Total Price for {{
                    item.itemName
                  }} is ₹ {{ item.total_price }}.</span>
                <hr style="border-style: groove"/>
              </div>
            </div>
            <div class="additional">
              <md-field>
                <label>CGST %</label>
                <md-input v-model="cgst" type="number"/>
              </md-field>
              <md-field>
                <label>SGST %</label>
                <md-input v-model="sgst" type="number"/>
              </md-field>
              <md-field>
                <label>IGST %</label>
                <md-input v-model="igst" type="number"/>
              </md-field>
            </div>
            <md-field>
              <label>Market Fees</label>
              <md-input v-model="marketFees" type="number"/>
            </md-field>
            <md-field>
              <label>Other Charges</label>
              <md-input v-model="otherFees" type="number"/>
            </md-field>
            <md-field>
              <label>Discount %</label>
              <md-input v-model="discountPercent" type="number"/>
            </md-field>
            <md-field>
              <label>Transport Fee</label>
              <md-input v-model="transport" type="number"/>
            </md-field>
            <span class="md-title" style="display: flex; justify-content: center; font-size: 30px">Total Price for all the above Items is ₹ {{
                itemTotalPrice
              }} </span><br>
            <span class="md-subheading" style="display: flex; justify-content: center; font-size: 20px">Discount Given : ₹ {{
                discount
              }}</span><br>
            <span class="md-subheading" style="display: flex; justify-content: center; font-size: 20px">Transport Applied : ₹ {{
                transport
              }}</span><br>
            <span class="md-subheading" style="display: flex; justify-content: center; font-size: 20px">Market Fees : ₹ {{
                marketFees
              }}</span><br>
            <span class="md-subheading" style="display: flex; justify-content: center; font-size: 20px">Other Charges : ₹ {{
                otherFees
              }}</span><br>
            <span class="md-subheading"
                  style="display: flex; justify-content: center; font-size: 20px">Grand Total ₹ {{ pretax }} (excluding tax)</span><br>
            <span class="md-subheading" style="display: flex; justify-content: center; font-size: 20px">GST Applied : ₹ {{
                gst_total
              }}</span><br>
            <span class="md-title" style="display: flex; justify-content: center; font-size: 30px">Grand Total : ₹ {{
                final_price
              }}</span>
            <md-button class="md-raised md-accent" style="position: relative; left: 0vw" v-on:click="print()">Submit &
              Print
            </md-button>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <div class="main_estimate">
      <img src="../assets/logo.png" style="max-width: 10vw; position: absolute; right: 90%; top: 0; z-index: -1"
           alt="Logo"><br><br>

      <span style="display: flex; justify-content: flex-end; font-size: medium; margin-top: -35px;" class="md-title">Mob: + 91- 9822408498</span><br>
      <span style="display: flex; justify-content: flex-end; font-size: medium; margin-top: -30px;" class="md-title">&emsp; +91- 9021949498</span><br>
      <span style="display: flex; justify-content: flex-end; font-size: medium; margin-top: -25px;" class="md-title">Phone: 02550-250798</span>

      <div class="header">
        <span style="font-size: xx-large; font-weight: bold">Gajendra&nbsp;Trading&nbsp;Co.</span><br><br>
        <span style="font-size: large;">Indian Dry Grapes - Commission Agent & General Merchant</span><br>
        <span style="font-size: small; font-weight: normal">Off: 2216, "Gajendra", Laxmibal Bhide Nagar, Near Shah Petrol Pump, PIMPALGAON(B), Tal: Niphad, Dist: Nashik - 422209</span><br>
        <span style="font-size: 25px; font-weight: bold; position: relative; top: -20px;"><span
            style="font-weight: bold; display: flex; justify-content: flex-start; font-size: 15px;  position: relative; top: 45px;">GSTIN : 27AHKPR1702P1ZW</span>Invoice<span
            style="font-weight: bold; display: flex; justify-content: flex-end; font-size: 15px;">{{
            TypeOfInvoice
          }}</span> </span>
      </div>
      <div style="margin-top: -60px;">
        <span style="display: flex; justify-content: flex-start;"
              class="md-subheading">Reverse Charge : {{ reverseCharge }} </span>
        <span style="display: flex; justify-content: flex-start;" class="md-subheading">Estimate Id :<b>&nbsp;{{
            estimate_id
          }} </b></span>
        <span style="display: flex; justify-content: flex-start;" class="md-subheading">Date : {{ date }}</span>
        <span style="display: flex; justify-content: flex-start;" class="md-subheading">State : Maharashtra State Code: 27</span><br>
      </div>
      <div style="margin-top: -120px;">
        <span style="display: flex; justify-content: flex-end;" class="md-subheading">Transportation Mode: {{transportMode}} </span>
        <span style="display: flex; justify-content: flex-end;" class="md-subheading">Vehicle Number: {{
            vehicleNumber
          }}</span>
        <span style="display: flex; justify-content: flex-end;" class="md-subheading">Date of supply : {{
            dateOfSupply
          }}</span>
        <span style="display: flex; justify-content: flex-end;"
              class="md-subheading">Place of Supply: {{ placeOfSupply }} </span>
      </div>
      <div class="details">
        <div class="client_details">
          <b style="text-align: center; font-size: 18px">Client Details</b><br>
          <b>To - </b>{{ clientDetails.Name }}<br>
          <b>Address - </b>{{ clientDetails.Address }}<br>
          <b>GSTIN - </b>{{ clientDetails.GSTIN }}<br>
          <b>State - </b>{{ clientDetails.State }}<br>
          <b>State Code - </b>{{ clientDetails.StateCode }}<br>
        </div>
        <div class="shipped_details">
          <b style="text-align: center; font-size: 18px">Shipped Details</b><br>
          <b> To - </b>{{ shippedDetails.Name }}<br>
          <b>Address - </b>{{ shippedDetails.Address }}<br>
          <b>GSTIN - </b>{{ shippedDetails.GSTIN }}<br>
          <b>State - </b>{{ shippedDetails.State }}<br>
          <b>State Code - </b>{{ shippedDetails.StateCode }}<br>
        </div>
      </div>
      <div style="margin-top: -20px;">
        <p class="md-headline">Indian Dry Grapes (Raisins) - HSN Code - 08062010</p>
        <table style="width:100%; margin-top: -25px;">
          <tr class="table_row">
            <th>Name</th>
            <th>Weight / Box</th>
            <th>No of Boxes</th>
            <th>Total Weight</th>
            <th>Rate</th>
            <th>Subtotal</th>
          </tr>
          <tr v-for="(item, index) in items" :key="index">
            <td class="table_row">{{ item.itemName }}</td>
            <td class="table_row">{{ item.itemWeightBox }}</td>
            <td class="table_row">{{ item.itemNoBox }}</td>
            <td class="table_row">{{ item.itemTotalWeight }} Kgs</td>
            <td class="table_row">₹ {{ item.itemPrice }}</td>
            <td class="table_row">₹ {{ item.total_price }}</td>
          </tr>
        </table>
        <table>
        </table>
      </div>
      <div class="BankDetails">
        <div class="md-title">Bank Details:</div>
        <div class="md-subheading">Bank Account Name: <b>GAJENDRA TRADING Co.</b></div>
        <div class="md-subheading">Bank Name: <b>AXIS BANK</b></div>
        <div class="md-subheading">Branch: <b>Pimpalgaon Baswant</b></div>
        <div class="md-subheading">Account No.: <b>913020042093834</b></div>
        <div class="md-subheading">IFSC Code: <b>UTIB0000511</b></div>
      </div>
      <div class="totals">
        <table>
          <tr>
            <th class="table_total_row">Total Category</th>
            <th class="table_total_row">Price</th>
          </tr>
          <tr>
            <td class="table_total_row"><b>Pre-Tax</b></td>
            <td class="table_total_row"><b>₹&nbsp;{{ pretax }}</b></td>
          </tr>
          <tr>
            <td class="table_total_row">Transport Applied</td>
            <td class="table_total_row">₹&nbsp;{{ transport }}</td>
          </tr>
          <tr>
            <td class="table_total_row">Other Fees</td>
            <td class="table_total_row">₹&nbsp;{{ otherFees }}</td>
          </tr>
          <tr>
            <td class="table_total_row">Market Fees</td>
            <td class="table_total_row">₹&nbsp;{{ marketFees }}</td>
          </tr>
          <tr>
            <td class="table_total_row"><b>Applied GST</b></td>
            <td class="table_total_row"><b>₹&nbsp;{{ gst_total }}</b></td>
          </tr>
          <tr v-if="discount !== '0.00'">
            <td class="table_total_row">Discount Given</td>
            <td class="table_total_row">₹&nbsp;{{ discount }}</td>
          </tr>
          <tr>
            <td class="table_total_row"><b>Grand Total</b></td>
            <td class="table_total_row"><b>₹&nbsp;{{ final_price }}</b></td>
          </tr>
          <tr>
            <td class="table_total_row">GST Payable of Reverse Charge</td>
            <td class="table_total_row">₹ PENDING</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import {getAuth, onAuthStateChanged} from "firebase/auth";
import router from "@/router";
import { serverTimestamp, setDoc, getFirestore,getDoc, doc, writeBatch } from "firebase/firestore/";


export default {
  name: 'In',
  components: {},
  beforeMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (!user)
        await router.replace('/');

    });
  },
  data() {
    return {
      fetch_id: null,
      estimate_id: (Math.random() + 1).toString(36).substring(7),
      clientDetails: {
        Name: "",
        Address: "",
        GSTIN: "",
        State: "",
        StateCode: "",
      },
      shippedDetails: {
        Name: "",
        Address: "",
        GSTIN: "",
        State: "",
        StateCode: "",
      },
      reverseCharge: 0,
      transportMode: "",
      vehicleNumber: "",
      placeOfSupply: "",
      dateOfSupplySelected: null,
      dateOfSupply: null,
      TypeOfInvoice: "Original for Receiver",
      items: [
        {
          itemName: "",
          itemNoBox: parseFloat("0"),
          itemWeightBox: 0,
          itemTotalWeight: 0,
          itemPrice: 0.0,
          total_price: 0.0,
        }
      ],
      cgst: 0.0,
      sgst: 0.0,
      igst: 0.0,
      marketFees: 0,
      otherFees: 0,
      discountPercent: 0,
      transport: 0,
      currentCount: [
        {
          Name: "",
          count: -1
        }
      ],
      newItemCheck: false,
      newItemNameTemp: "",
      newItemIndex: -1,
    }
  },
  watch:{
    dateOfSupplySelected: function (){
      const today = this.dateOfSupplySelected;
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      this.dateOfSupply = dd + "/" + mm + "/" + yyyy;
    }
  },
  computed: {
    itemTotalPrice: function () {
      let total = 0;
      for (let i = 0; i < this.items.length; i++)
        total += this.items[i].total_price
      return total.toFixed(2);
    },
    pretax: function () {
      return parseFloat(this.itemTotalPrice) + parseFloat(this.marketFees) + parseFloat(this.otherFees) + parseFloat(this.transport);
    },
    date: function () {
      const event = new Date(Date.now());
      const options = {year: "numeric", month: "numeric", day: "numeric"};
      return (event.toLocaleDateString('en-IN', options));
    },
    discount: function () {
      return (isNaN(parseFloat(this.discountPercent) / 100) * this.pretax ? 0.0 : (parseFloat(this.discountPercent) / 100) * this.pretax).toFixed(2);
    },
    gst_total: function () {
      const cgst = isNaN(parseFloat(this.cgst)) ? 0.0 : parseFloat(this.cgst);
      const sgst = isNaN(parseFloat(this.sgst)) ? 0.0 : parseFloat(this.sgst);
      const igst = isNaN(parseFloat(this.igst)) ? 0.0 : parseFloat(this.igst);
      const gst = cgst + sgst + igst;
      return (isNaN((gst / 100.0) * (this.pretax - this.discount)) ? 0.0 : (gst / 100.0) * (this.pretax - this.discount)).toFixed(2);
    },
    final_price: function () {
      return (parseFloat(this.pretax) - parseFloat(this.discount) + parseFloat(this.gst_total) + parseFloat(this.transport)).toFixed(2);
    }
  },
  methods: {
    async ItemCountUpdater(index){
      const db = getFirestore(this.$app);
      this.currentCount[index].count = -1;
      this.currentCount[index].Name = "";
      if (this.items[index].itemName !== "") {
        const docRef = doc(db, "inventory", this.items[index].itemName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          this.currentCount[index].Name = this.items[index].itemName;
          this.currentCount[index].count = docSnap.data()['Count'];
        } else {
          console.log("Not Found");
        }
      }
    },
    CheckItem(index){
      if (this.currentCount[index].count === -1) {
        this.newItemCheck = true;
        this.newItemNameTemp = this.items[index].itemName;
        this.newItemIndex = index;
      }
    },
    newItem(){
      this.newItemCheck = false;
      this.currentCount[this.newItemIndex].Name = this.newItemNameTemp;
      this.currentCount[this.newItemIndex].count = 0;
      this.newItemNameTemp = "";
      this.newItemIndex = -1;
    },
    async print() {
      const db = getFirestore(this.$app);
      await setDoc(doc(db, "transactions", this.estimate_id), {
        clientDetails: this.clientDetails,
        receiverDetails: this.shippedDetails,
        data: this.items,
        Timestamp: serverTimestamp(),
        gst_rate: [this.cgst, this.sgst, this.igst],
        marketFees: parseInt(this.marketFees  === undefined ? 0.0 : this.marketFees),
        otherCharges: parseInt(this.otherFees === undefined ? 0.0 : this.otherFees),
        discountPerc: parseInt(this.discountPercent  === undefined ? 0.0 : this.discountPercent),
        transportFees: parseInt(this.transport === undefined ? 0.0 : this.transport ),
        grandTotal: parseInt(this.final_price  === undefined ? 0.0 : this.final_price),
        Type: "In"
      });
      const batch = writeBatch(db);
      for (var i = 0; i < this.currentCount.length; i++){
        batch.set(doc(db, "inventory", this.currentCount[i].Name),{Count: parseInt(this.currentCount[i].count) + parseInt(this.items[i].itemNoBox)});
      }
      await batch.commit();
      window.print();
    },
    item_total_updater(index) {

      this.items[index].itemTotalWeight = parseInt(this.items[index].itemNoBox) * parseInt(this.items[index].itemWeightBox);
      this.items[index].total_price = parseInt(this.items[index].itemTotalWeight) * parseInt(this.items[index].itemPrice);
      if (isNaN(this.items[index].total_price))
        this.items[index].total_price = 0.0;
    },
    add() {
      this.items.push(
          {
            itemName: "",
            itemNoBox: 0,
            itemWeightBox: 0,
            itemTotalWeight: 0,
            itemPrice: 0.0,
            total_price: 0.0,
          }
      )
      this.currentCount.push({
        Name: "",
        count: -1
      })
    },
    remove(index) {
      this.items.splice(index, 1);
      this.currentCount.splice(index,1);
      if (index === 0)
        this.add();
      this.newItemIndex = -1;
    },
  },
}
</script>

<style scoped>


.main_estimate {
  display: none;
  width: 100%;
}

.header {
  text-align: center;
  position: relative;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
}

.client_details {
  justify-content: start;
  max-width: 40vw;
  font-size: 15px;
}

.shipped_details {
  margin-left: auto;
  margin-right: 0;
  max-width: 40vw;
  font-size: 15px;
}

.details {
  display: flex;
  text-align: left;
}

.totals {
  min-width: 45vw;
  position: absolute;
  left:50%;
  font-size: 20px;
  margin-top: 0px;
}

.data_picker {
  display: block;
}

.itemName {
  page-break-inside: auto;
}

.toolbar-content {
  border-radius: 10px;
}

.toolbar {
  padding: 10px;
}


.quotation-details {
  min-width: 50vw;
  margin: 0 10px 0 10px;
  border-radius: 25px;
}


.quotation {
  padding: 0;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.additional {
  display: flex;
  align-items: baseline;
}

.table_row, table {
  border: 1px solid;
  font-size: 17px;
}

.table_total_row {
  border: 1px solid;
}

.BankDetails{
  border: 1px solid;
  padding: 1px;
  min-width: 20vw;
  position: absolute;
  left: 0%;
  font-size: 20px;
  margin-top: 10px;
}

@media screen and (max-width: 1024px) {
  .quotation-details {
    min-width: 90vw;
  }

  .additional {
    display: inline-block;
  }

}


.cut {
  display: flex;
  justify-content: flex-end;
}


@media print {
  @page {
    size: portrait;
    min-width: 100vw;
    margin: 10px;
  }

  .data_picker {
    display: none;
  }

  .main_estimate {
    display: block;
  }

  .quotation-details {
    min-width: 90vw;
  }

  .toolbar {
    display: none;
  }

  .cut_inset {
    display: none;
  }

  .cut {
    display: none;
  }

  .unit {
    display: none;
  }

}


</style>