import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import dashboard from "@/views/dashboard";
import In from "@/views/In";
import Out from "@/views/Out";
import Live from "@/views/Live";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: dashboard
    },
    {
        path: '/NewIn',
        name: 'In',
        component: In
    },
    {
        path: '/NewOut',
        name: 'Out',
        component: Out
    },
    {
        path: '/ViewLiveInventory',
        name: 'Live',
        component: Live
    },
    {
        path: '/*',
        name: '404',
        // goes back to login
        component: () => {
            router.replace('/')
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router
