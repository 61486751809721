<template>
  <div class="main_table">
      <md-table v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header>
        <md-table-toolbar>
          <div class="md-toolbar-section-start">
            <h1 class="md-title">Data</h1>
          </div>

          <md-field md-clearable class="md-toolbar-section-end">
            <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable"/>
          </md-field>
        </md-table-toolbar>

        <md-table-empty-state
            md-label="No users found"
            :md-description="`No data found for this '${search}' query. Try a different search term.`">
        </md-table-empty-state>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
          <md-table-cell md-label="Count" md-sort-by="count" md-numeric>{{ item.count }}</md-table-cell>
        </md-table-row>
      </md-table>
  </div>
</template>

<script>
import {getAuth, onAuthStateChanged} from "firebase/auth";
import router from "@/router";
import {collection, getDocs, getFirestore} from "firebase/firestore/";

const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.name).includes(toLower(term)))
  }

  return items
}
export default {
  name: "Live",
  beforeMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (!user)
        await router.replace('/');
    });
  },
  async mounted() {
    const db = getFirestore(this.$app);
    const querySnapshot = await getDocs(collection(db, "inventory"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      this.users.push({name: doc.id, count: doc.data()['Count']})
    });
  },
  data: () => ({
    search: null,
    searched: [],
    users: []
  }),
  methods: {
    newUser() {
      window.alert('Noop')
    },
    searchOnTable() {
      this.searched = searchByName(this.users, this.search)
    }
  },
  created() {
    this.searched = this.users
  }
}
</script>

<style scoped>

.main_table{
}

</style>