<template>
  <div class="Form">
    <form novalidate class="" @submit.prevent="">
      <md-card class="md-small-size-100 md-elevation-15">
        <md-card-header>
          <div class="md-display-1">Login</div>
        </md-card-header>
        <md-card-content>
          <div class="">
            <div class="">
              <md-field :class="getValidationClass('email')">
                <label for="email">Email Id</label>
                <md-input name="email" id="email" autocomplete="given-name" v-model="form.email"
                          :disabled="sending"/>
                <span class="md-error" v-if="!$v.form.email.required">Email Id is required</span>
                <span class="md-error" v-if="!$v.form.email.email">Enter a valid Email Id</span>

              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-content>
          <div class="">
            <div class="">
              <md-field :class="getValidationClass('password')">
                <label for="password">Password</label>
                <md-input name="password" id="password" type="password" autocomplete="password" v-model="form.password"
                          :disabled="sending"/>
                <span class="md-error" v-if="!$v.form.password.required">Password is required</span>
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-button type="submit" class="md-raised md-accent login" :disabled="sending" @click="validateData">Login
        </md-button>
        <br>
        <md-progress-spinner class="md-accent" :md-stroke="3" :md-diameter="30" md-mode="indeterminate"
                             v-show="this.sending"></md-progress-spinner>
      </md-card>
      <md-snackbar :md-duration="2000" :md-active.sync="showSnackbar" md-persistent> {{ this.errorMessage }}
        <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
      </md-snackbar>
    </form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {
  required,
} from 'vuelidate/lib/validators'
import {
  getAuth,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  setPersistence,
  onAuthStateChanged
} from "firebase/auth";
import email from "vuelidate/lib/validators/email";
import router from "@/router";
export default {
  name: 'FormValidation',
  mixins: [validationMixin],
  data() {
    return {
      auth: getAuth(),
      form: {
        email: null,
        password: null
      },
      sending: false,
      showSnackbar: false,
      errorMessage: "",
    }
  },
  validations: {
    form: {
      email: {
        email,
        required,
      },
      password: {
        required,
      }
    }
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    async cookieCheck() {

    },

    async Login() {
      setPersistence(this.auth, browserLocalPersistence).then(() => {
        signInWithEmailAndPassword(this.auth, this.form.email, this.form.password)
            .then(() => {
              // Signed in
              router.push("/dashboard");
            })
            .catch((error) => {
              const errorCode = error.code;
              if (errorCode === "auth/wrong-password") {
                this.errorMessage = "Wrong Password";
                this.showSnackbar = true;
              }
              // ..
            });
      });

    },
    validateData() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.Login();
        window.setTimeout(() => {
          this.sending = false
        }, 10)
      }
    }
  },
  beforeMount() {
    onAuthStateChanged(this.auth, async (user) => {
      if (await user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        if (uid !== null && uid !== undefined)
          router.replace("/dashboard")
        // ...
      }
    });
    },
  mounted() {
  }
}
</script>

<style scoped>

.Form {
}

.md-card {
  min-width: 40vw;
  margin: 20px 0 0 0;
  padding: 20px;
  display: inline-block;
  vertical-align: center;
  border-radius: 10px;
}

@media screen and (max-width: 1024px) {
  .md-card {
    min-width: 90vw;
    padding: 5px;
  }
}


.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
</style>